import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { viewCategoryReducer as reducer } from "../../reducers/category";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
  Accordion,
} from "react-bootstrap";
import MessageBox from "../layout/MessageBox";
import EditCategoryModel from "./EditCategory.js";
// import SubCategoryTable from "./SubCategoryTable";
import axiosInstance from "../../utils/axiosUtil";
import { FaEdit, FaEye } from "react-icons/fa";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import CustomSkeleton from "../layout/CustomSkeleton";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";

const ViewCategory = () => {
  const { state } = useContext(Store);
  const { token } = state;
  const { id } = useParams(); // category/:id
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [
    { loading, error, category, categoryProducts },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });

        const { data: categoryProducts } = await axiosInstance.get(
          `/api/category/${id}/products`,
          {
            headers: { Authorization: token },
          }
        );



        const { data } = await axiosInstance.get(`/api/category/${id}`, {
          headers: { Authorization: token },
        });
        // console.log(categoryProducts);

        const category = data.category;

        const payloadData = { categoryProducts, category };

        dispatch({
          type: "FETCH_SUCCESS",
          payload: payloadData,
        
        });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
  }, [id]);

  const getDateTime = (dt) => {
    const dT = dt.split(".")[0].split("T");
    return `${dT[0]} ${dT[1]}`;
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <Card>
              <Card.Header>
                <Card.Title>
                  {loading ? <Skeleton /> : category.name} category Details
                </Card.Title>

                <div className="card-tools">
                  <FaEdit
                    style={{ color: "blue" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col md={4}>
                    {loading ? (
                      <Skeleton height={200} />
                    ) : (
                      <img
                        src={category.category_image}
                        alt=""
                        className="img-fluid"
                        width={"200px"}
                        // height={"200px"}
                      />
                    )}
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Name</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : category.name}</p>
                      </Col>
                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Description</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : category.description}</p>
                      </Col>
                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Created At</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            getDateTime(category.createdAt)
                          )}
                        </p>
                      </Col>
                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Last Update</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            getDateTime(category.updatedAt)
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  {loading ? (
                    <Skeleton height={300} />
                  ) : (
                    <div style={{ marginTop: "8%" }}>
                      

                      <Accordion defaultActiveKey="0">
                       
                      </Accordion>
                    </div>
                  )}
                </Row>

                {/* <Row>
                  <SubCategoryTable id={id} />
                </Row> */}
              </Card.Body>
            </Card>
            <EditCategoryModel
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

            {!modalShow && <ToastContainer />}
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default ViewCategory;
