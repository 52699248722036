const satisfiedCustomerReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        satisfiedCustomers: action.payload.satisfiedCustomers,
        satisfiedCustomerCount: action.payload.satisfiedCustomerCount,
        filteredSatisfiedCustomerCount:
          action.payload.filteredSatisfiedCustomerCount,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const addSatisfiedCustomerReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        satisfiedCustomer: action.payload.satisfiedCustomer,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const viewSatisfiedCustomerReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        satisfiedCustomer: action.payload.satisfiedCustomer,
        createdAt: action.createdAt,
        updatedAt: action.updatedAt,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export {
  satisfiedCustomerReducer,
  viewSatisfiedCustomerReducer,
  addSatisfiedCustomerReducer,
};
