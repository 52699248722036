import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { viewStaticContentReducer as reducer } from "../../reducers/staticContent";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, Col, Container, Row } from "react-bootstrap";
import MessageBox from "../layout/MessageBox";
// import EditProductModel from "./EditProduct.js";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import axiosInstance from "../../utils/axiosUtil";
import { FaEdit } from "react-icons/fa";
// import ProductReviewTable from "./ProductReviewTable";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
// import QuantityArray from "../listView/QuantityArray";
import { IoMdOpen } from "react-icons/io";
import EditStaticContent from "./EditStaticContent";

const ViewStaticContent = () => {
  const { state } = useContext(Store);
  const { token } = state;
  const { id } = useParams();

  const [{ loading, error, staticContent }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const [modalShow, setModalShow] = useState(false);

  // const [arrView, setArrView] = useState(false);
  // const showModelHandler = () => {
  //   setArrView(true);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });

        const { data } = await axiosInstance.get(
          `/api/admin/get/static/${id}`,
          {
            headers: { Authorization: token },
          }
        );
        // console.log("get static ", data);

        dispatch({ type: "FETCH_SUCCESS", payload: data[0] });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
    return () => {};
  }, [id]);

  const getDateTime = (dt) => {
    if (!dt) return;
    const dT = dt.split(".")[0].split("T");
    return `${dT[0]} ${dT[1]}`;
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <Card>
              <Card.Header>
                <Card.Title>
                  {loading ? <Skeleton /> : "Company Details"}
                </Card.Title>
                <div className="card-tools">
                  <FaEdit
                    style={{ color: "blue" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <>
                    <>
                      <Col md={"auto"}>
                        <p className="mb-0">
                          <strong>About Comapny</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : staticContent?.aboutUs}</p>
                      </Col>

                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Phone</strong>
                        </p>
                        {staticContent?.contactUs?.phone?.map((phone) => (
                          <p key={phone}>
                            {loading ? (
                              <Skeleton />
                            ) : (
                              <ul>
                                <li>{phone}</li>
                              </ul>
                            )}
                          </p>
                        ))}
                      </Col>

                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Email</strong>
                        </p>
                        {staticContent?.contactUs?.email?.map((email) => (
                          <p key={email}>
                            {loading ? (
                              <Skeleton />
                            ) : (
                              <ul>
                                <li>{email}</li>
                              </ul>
                            )}
                          </p>
                        ))}
                      </Col>

                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Address</strong>
                        </p>
                        {staticContent?.contactUs?.address?.map((address) => (
                          <p key={address}>
                            {loading ? (
                              <Skeleton />
                            ) : (
                              <ul>
                                <li>{address}</li>
                              </ul>
                            )}
                          </p>
                        ))}
                      </Col>

                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Created At</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            getDateTime(staticContent?.createdAt)
                          )}
                        </p>
                      </Col>
                      <Col md={4}>
                        <p className="mb-0">
                          <strong>Last Update</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            getDateTime(staticContent?.updatedAt)
                          )}
                        </p>
                      </Col>
                    </>
                  </>
                </Row>
              </Card.Body>
            </Card>

            {/* {arrView ? (
              <QuantityArray
                show={arrView}
                onHide={() => setArrView(false)}
                arr={product.subProduct}
                column={{"Quantity Type": "qname","Amount": "amount"}}
                title="Price List"
              />
            ) : (
              <></>
            )} */}
            <EditStaticContent
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <ToastContainer />
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default ViewStaticContent;
