import React from "react";
import { Table, Button } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const VendorTable = ({ vendor }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-3">
        <h3>Vendor</h3>
      </div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile No.</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr key={vendor._id} className="odd">
            <td className="text-center">{1}</td>

            <td>{vendor?.fullname}</td>
            <td>{vendor?.email}</td>
            <td>{vendor?.mobile_no}</td>

            <td>
              <Button
                onClick={() => {
                  navigate(`/admin/view/vendor/${vendor._id}`);
                }}
                type="success"
                className="btn btn-primary"
              >
                <FaEye />
              </Button>
              {/* <Button
                    onClick={() => {
                      deleteProduct(vendor._id);
                    }}
                    type="danger"
                    className="btn btn-danger ms-2"
                  >
                    <FaTrashAlt />
                  </Button> */}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default VendorTable;
