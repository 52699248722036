import React, { useContext, useEffect, useReducer, useState } from "react";
import Chart from "react-google-charts";
import axiosInstance from "../../utils/axiosUtil";
import { Store } from "../../Store";

import { getError } from "../../utils/error";

import MessageBox from "./MessageBox";
import Skeleton from "react-loading-skeleton";
import { Form, Container, Card, Row, Col, Accordion } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { IoIosPerson, IoIosPersonAdd, IoMdPie } from "react-icons/io";
import { GiNetworkBars } from "react-icons/gi";
import { FaArrowCircleRight } from "react-icons/fa";
import { motion } from "framer-motion";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        summary: action.payload.summaryData,
        intermediaries: action.payload.intermeInfo.intermediaries,
        clients: action.payload.clients,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "CLIENTS_FETCH_REQUEST":
      return { ...state, clientsLoading: true };
    case "CLIENTS_FETCH_SUCCESS":
      return {
        ...state,
        clients: action.payload.intermediaries.intermediaryClients,
        intermediariesQuo: action.payload.intermediariesQuo.quote,
        clientsLoading: false,
      };
    case "CLIENTS_FETCH_FAIL":
      return { ...state, clientsLoading: false, error: action.payload };
    case "CLIENTS_QUOTES_FETCH_REQUEST":
      return { ...state, clientsQuotesLoading: true };
    case "CLIENTS_QUOTES_FETCH_SUCCESS":
      return {
        ...state,
        clientsQuotes: action.payload.quote,
        clientsQuotesLoading: false,
      };
    case "CLIENTS_QUOTES_FETCH_FAIL":
      return { ...state, clientsQuotesLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default function Dashboard() {
  const [
    {
      loading,
      summary,
      error,
      intermediaries,
      clients,
      clientsLoading,
      clientsQuotesLoading,
      clientsQuotes,
      intermediariesQuo,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const { state } = useContext(Store);
  const { token } = state;
  const [time, setTime] = useState("all");

  /*useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data: summaryData } = await axiosInstance.get(
          `/api/admin/statistics/${time}`,
          {
            headers: { Authorization: token },
          }
        );

        const { data: intermeInfo } = await axiosInstance.get(
          `/api/admin/intermediariesInfo`,
          { headers: { Authorization: token } }
        );
        // console.log(res.data);

        const payloadData = { summaryData, intermeInfo };

        // console.log(payloadData);
        // console.log(data);
        dispatch({ type: "FETCH_SUCCESS", payload: payloadData });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
  }, [token, time]);

  const getClients = async (type, id) => {
    if (type === "intermediary") {
      try {
        dispatch({ type: "CLIENTS_FETCH_REQUEST" });

        const { data: intermediaries } = await axiosInstance.get(
          `/api/admin/get-intermediary/${id}`,
          {
            headers: { Authorization: token },
          }
        );

        const { data: intermediariesQuo } = await axiosInstance.get(
          `/api/admin/get-clientQuotes/${id}`,
          {
            headers: { Authorization: token },
          }
        );
        // console.log(intermediariesQuo);
        // console.log(res.data.quote);

        // console.log(data?.intermediaryClients);
        const payloadData = { intermediaries, intermediariesQuo };
        dispatch({
          type: "CLIENTS_FETCH_SUCCESS",
          payload: payloadData,
        });
      } catch (err) {
        dispatch({
          type: "CLIENTS_FETCH_FAIL",
          payload: getError(err),
        });

        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      try {
        dispatch({ type: "CLIENTS_QUOTES_FETCH_REQUEST" });

        const { data } = await axiosInstance.get(
          `/api/admin/get-clientQuotes/${id}`,
          {
            headers: { Authorization: token },
          }
        );

        dispatch({
          type: "CLIENTS_QUOTES_FETCH_SUCCESS",
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: "CLIENTS_QUOTES_FETCH_FAIL",
          payload: getError(err),
        });

        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };*/

  return (
    <></>
  );
}
