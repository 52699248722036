import { Store } from "./Store";
import { useContext, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";


import AdminProtectedRoute from "./components/protectedRoute/AdminProtectedRoute";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import SideNavbar from "./components/layout/SideNavBar";
import NotFound from "./components/layout/NotFound";

import ViewProfile from "./components/profile/ViewProfile";

import Category from "./components/category/Category";
import AddCategory from "./components/category/AddCategory";
import ViewCategory from "./components/category/ViewCategory";

import Products from "./components/product/Products";
import AddProduct from "./components/product/AddProduct";
import ViewProduct from "./components/product/ViewProduct";

import Promotions from "./components/promotion/Promotion";
import AddPromotion from "./components/promotion/AddPromotion";
import ViewPromotion from "./components/promotion/ViewPromotion";


import StaticContent from "./components/staticContent/StaticContent";
import AddStaticContent from "./components/staticContent/AddStaticContent";
import ViewStaticContent from "./components/staticContent/ViewStaticContent";





import AdminLoginScreen from "./components/AdminLoginScreen";
import Dashboard from "./components/layout/Dashboard";
import UnprotectedRoute from "./components/protectedRoute/UnprotectedRoute";


import SatisfiedCustomers from "./components/satisfiedCustomers/SatisfiedCustomers";
import AddSatisfiedCustomer from "./components/satisfiedCustomers/AddSatisfiedCustomers";
import ViewSatisfiedCustomer from "./components/satisfiedCustomers/ViewSatisfiedCustomers";

import LatestNews from "./components/latestNews/LatestNews";
import AddLatestNews from "./components/latestNews/AddLatestNews";
import ViewLatestNews from "./components/latestNews/ViewLatestNews";


import IntermeSideNavBar from "./components/intermediaryPanel/sideBar/IntermeSideNavBar";

import ContactusDetails from "./components/contact-us/ContactusDetails";
import ViewDetails from "./components/contact-us/ViewDetails";


function App() {
  const { state } = useContext(Store);
  const { token, userInfo } = state;

  const pageLocation = useLocation();

  const [isExpanded, setExpandState] = useState(window.innerWidth > 768);
  const sidebarHandler = () => setExpandState((prev) => !prev);

  const routeList = [
    { path: "/admin/dashboard", element: <Dashboard /> },
    //{ path: "/view-profile", element: <ViewProfile /> },
    //{ path: "/admin/users", element: <Users /> },
    { path: "/admin/companyDetails", element: <StaticContent /> },
    { path: "/admin/companyDetails/add", element: <AddStaticContent /> },
    { path: "/admin/view/companyDetails/:id", element: <ViewStaticContent /> },
    //{ path: "/admin/view/user/:id", element: <ViewUser /> },
    //{ path: "/admin/quoteRequests", element: <QuoteRequests /> },
    //{ path: "/admin/view/quoteRequest/:id", element: <ViewQuoteRequest /> },
    //{ path: "/admin/installers", element: <Installers /> },
    //{ path: "/admin/installer/add", element: <AddInstaller /> },
    //{ path: "/admin/view/installer/:id", element: <ViewInstaller /> },
    //{ path: "/admin/intermediaries", element: <Intermediaries /> },
    //{ path: "/admin/intermediary/add", element: <AddIntermediary /> },
    //{ path: "/admin/view/intermediary/:id", element: <ViewIntermediary /> },
    { path: "/admin/queries", element: <ContactusDetails /> },
    { path: "/admin/view/query/:id", element: <ViewDetails /> },
    { path: "/admin/category", element: <Category /> },
    { path: "/admin/category/create", element: <AddCategory /> },
    { path: "/admin/view/category/:id", element: <ViewCategory /> },
    { path: "/admin/products", element: <Products /> },
    { path: "/admin/product/create", element: <AddProduct /> },
    { path: "/admin/view/product/:id", element: <ViewProduct /> },
  
    { path: "/admin/promotions", element: <Promotions /> },
    { path: "/admin/promotion/create", element: <AddPromotion /> },
    { path: "/admin/view/promotion/:id", element: <ViewPromotion /> },
    //{ path: "/admin/orders", element: <Order /> },
    //{ path: "/admin/view/order/:id", element: <ViewOrder /> },
    //{ path: "/admin/sale", element: <Sale /> },
    //{ path: "/admin/sale/create", element: <AddSale /> },
    //{ path: "/admin/view/sale/:id", element: <ViewSale /> },
    //{ path: "/admin/vendors", element: <Vendors /> },
    //{ path: "/admin/view/vendor/:id", element: <ViewVendor /> },
    //{ path: "/admin/vendor/add", element: <AddVendor /> },
    { path: "/admin/satisfiedCustomers", element: <SatisfiedCustomers /> },
    {
      path: "/admin/satisfiedCustomers/add",
      element: <AddSatisfiedCustomer />,
    },
    {
      path: "/admin/view/satisfiedCustomer/:id",
      element: <ViewSatisfiedCustomer />,
    },
    { path: "/admin/latestNews", element: <LatestNews /> },
    { path: "/admin/view/latestNews/:id", element: <ViewLatestNews /> },
    { path: "/admin/latestNews/add", element: <AddLatestNews /> },
  ];

  
  return (
    <div className="main-wrapper">
      {isExpanded && token && (
        <div className="sidebar-overlay" onClick={sidebarHandler}></div>
      )}
      <div className="sidebar-wrapper">
        {/* <Menu/> */}
        {userInfo?.role === "intermediary" ? (
          <IntermeSideNavBar isExpanded={isExpanded} />
        ) : (
          <SideNavbar isExpanded={isExpanded} />
        )}
      </div>
      <div
        className={`body-wrapper ${isExpanded ? "mini-body" : "full-body"} ${
          token ? "" : "m-0"
        } d-flex flex-column`}
      >
        <Header sidebarHandler={sidebarHandler} />
        <Routes location={pageLocation} key={pageLocation.pathname}>
          <Route
            path="/"
            element={
              <UnprotectedRoute>
                <AdminLoginScreen />
              </UnprotectedRoute>
            }
          />
          {routeList.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<AdminProtectedRoute>{element}</AdminProtectedRoute>}
            />
          ))}
          
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
