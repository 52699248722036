import React, { useContext, useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { addStaticContentReducer as reducer } from "../../reducers/staticContent";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import axiosInstance from "../../utils/axiosUtil";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

export default function AddStaticContent() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;

  const [{ loading, error, staticContent }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  const [aboutUs, setAboutUs] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [contactUs, setContactUs] = useState([]);
  const resetForm = () => {
    setAboutUs("");
    // setPhone("");
    // setEmail();
    // setAddress("");
    setContactUs([]);
  };

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!aboutUs) {
      toast.warning("All fields are required!.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    if (contactUs?.length <= 0) {
      toast.warning("Please provide at least one contact info.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    try {
      setLoadingUpdate(true);

      const { data } = await axiosInstance.post(
        "/api/admin/add/static",
        {
          aboutUs,
          contactUs,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (data) {
        toast.success("Company Info Added Succesfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        resetForm();
        setTimeout(() => {
          navigate("/admin/companyDetails");
          setLoadingUpdate(false);
        }, 3000);
      } else {
        toast.error(data.error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoadingUpdate(false);
      }
    } catch (err) {
      setLoadingUpdate(false);
      toast.error(getError(err), {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const contactUsHandler = () => {
    if (!phone || phone?.length < 10) {
      toast.warning("Phone is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!email) {
      toast.warning("Email is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!address) {
      toast.warning("Address is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    contactUs?.push({ phone, email, address });
    setPhone("");
    setEmail("");
    setAddress("");
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid>
        <Row
          className="mt-2 mb-3"
          style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
        >
          <Col>
            <span style={{ fontSize: "xx-large" }}>Add Company Details</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header as={"h4"}>Add Details</Card.Header>
              <Form onSubmit={submitHandler}>
                <Card.Body>
                  <Form.Group className="mb-3" controlId="aboutUs">
                    <Form.Label>About company</Form.Label>
                    <Form.Control
                      value={aboutUs}
                      onChange={(e) => setAboutUs(e.target.value)}
                      required
                      as="textarea"
                    />
                  </Form.Group>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          as={"textarea"}
                        />
                      </Form.Group>
                    </Col>

                    <Button className="mt-4" onClick={contactUsHandler}>
                      Add Contact Info
                    </Button>
                  </Row>

                  <Row>
                    {contactUs && contactUs.length > 0 && (
                      <div className="table-responsive">
                        <table
                          id="example1"
                          className="table table-bordered table-striped col-6"
                        >
                          <thead>
                            <tr>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contactUs.map(({ phone, email, address }, i) => (
                              <tr
                                key={contactUs?.findIndex(
                                  (q) =>
                                    q.phone === phone &&
                                    q.email === email &&
                                    q.address === address
                                )}
                              >
                                <td>{phone}</td>
                                <td>{email}</td>
                                <td>{address}</td>
                                <td>
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const index = contactUs.findIndex(
                                        (q) =>
                                          q.phone === phone &&
                                          q.email === email &&
                                          q.address === address
                                      );
                                      // console.log({ index });
                                      if (index > -1) {
                                        // only splice array when item is found

                                        setContactUs([
                                          ...contactUs?.slice(0, index),

                                          // part of the array after the given item
                                          ...contactUs?.slice(index + 1),
                                        ]);
                                      }
                                    }}
                                    type="danger"
                                    className="btn btn-danger btn-block"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Button type="submit" disabled={loadingUpdate ? true : false}>
                    {loadingUpdate ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Card.Footer>
                <ToastContainer />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
