import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Store } from "../../../Store";
import "./SideNavBar.css";
import { RiChatQuoteFill, RiDashboard2Fill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { MdCategory, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { BiCategory, BiCircle } from "react-icons/bi";
import { TbNumbers, TbDiscount2 } from "react-icons/tb";
import { GrContactInfo } from "react-icons/gr";
import {
  FaAngleDown,
  FaAngleUp,
  FaStarHalfAlt,
  FaMagic,
  FaShoppingBasket,
  FaSignOutAlt,
} from "react-icons/fa";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";

const linkList = [
  {
    icon: <RiDashboard2Fill className="icon-md" />,
    text: "Dashboard",
    url: "/intermediary/dashboard",
  },

  {
    icon: <HiUsers className="icon-md" />,
    text: "My Clients",
    url: "/intermediary/myClients",
  },
  {
    icon: <BsFillChatSquareQuoteFill className="icon-md" />,
    text: "Client Quotes",
    url: "/intermediary/quoteRequests",
  },
  {
    icon: <RiChatQuoteFill className="icon-md" />,
    text: "Your Quotes",
    url: "/intermediary/myQuoteRequests",
  },
];

const active_text = {
  Dashboard: "dashboard",
  "My Clients": "myClients",
  "Client Quotes": "quoteRequests",
  "Your Quotes": "myQuoteRequests",
};

export default function IntermeSideNavBar({ isExpanded }) {
  const pathname = window.location.pathname;
  const [activeLink, setActiveLink] = useState("Dashboard");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");

    navigate("/");
  };

  const activeLinkHandler = (text) => {
    return pathname.includes(active_text[text]);
  };

  return (
    <>
      {userInfo ? (
        <div
          className={
            isExpanded
              ? "side-nav-container"
              : "side-nav-container side-nav-container-NX"
          }
        >
          <div className="brand-link">
            <img src="/logo.jpg" alt="" width={"50px"} height="auto" />
            <span className="brand-text ms-2 font-weight-light">
              Edwin Intermediary
            </span>
          </div>

          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                <Link to="/intermediary/view-profile" className="d-block">
                  {userInfo.profile_image && (
                    <img
                      src={userInfo.profile_image}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "0.5rem",
                      }}
                    />
                  )}
                  <span className="info-text">
                    Welcome{" "}
                    {userInfo
                      ? `${userInfo.firstname} ${userInfo.lastname}`
                      : "Back"}
                  </span>
                </Link>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul
                className="nav-pills nav-sidebar px-0 d-flex flex-column flex-wrap"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {linkList.map(({ icon, text, url }) => (
                  <li
                    key={url}
                    className={`nav-item has-treeview ${
                      isExpanded ? "menu-item" : "menu-item menu-item-NX"
                    } ${activeLinkHandler(text) && "active-item"}`}
                    onClick={() => setActiveLink(text)}
                  >
                    <Link to={url} className="nav-link">
                      {icon}
                      <p className="ms-2">{text}</p>
                    </Link>
                  </li>
                ))}

                <li
                  className={`nav-item has-treeview ${
                    isExpanded ? "menu-item" : "menu-item menu-item-NX"
                  }`}
                >
                  <Link onClick={signoutHandler} to="/" className="nav-link">
                    {/* <i className="fas fa-sign-out-alt"></i> */}
                    <FaSignOutAlt className="icon-md" />
                    <p className="ms-2">Log Out</p>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
