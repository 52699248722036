import React, { useContext, useEffect, useReducer, useState } from "react";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { staticContentReducer as reducer } from "../../reducers/staticContent";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MessageBox from "../layout/MessageBox";
import {
  Button,
  Card,
  Container,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import CustomPagination from "../layout/CustomPagination";
import axiosInstance from "../../utils/axiosUtil";
import { FaEye, FaSearch, FaTrashAlt } from "react-icons/fa";
import { motion } from "framer-motion";
import CustomSkeleton from "../layout/CustomSkeleton";
import { IoMdOpen } from "react-icons/io";
import QuantityArray from "../listView/QuantityArray";
import EditStaticContent from "./EditStaticContent";

export default function StaticContent() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;

  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
  const [del, setDel] = useState(false);

  const curPageHandler = (p) => setCurPage(p);
  const [{ loading, error, staticContents }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  //   const deleteProduct = async (id) => {
  //     if (
  //       window.confirm(
  //         "Are you sure you want to delete this product?\n\nNote: All reviews of this product will also be deleted."
  //       ) === true
  //     ) {
  //       try {
  //         setDel(true);
  //         const res = await axiosInstance.delete(`/api/admin/product/${id}`, {
  //           headers: { Authorization: token },
  //         });
  //         setDel(false);
  //       } catch (error) {
  //         toast.error(getError(error), {
  //           position: toast.POSITION.BOTTOM_CENTER,
  //         });
  //       }
  //     }
  //   };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const res = await axiosInstance.get(`/api/admin/get/staticCont`, {
          headers: { Authorization: token },
        });
        // console.log("res staticCon ", res.data);
        dispatch({ type: "FETCH_SUCCESS", payload: res.data });
      } catch (error) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(error),
        });
        toast.error(getError(error), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
  }, [token, del, curPage, resultPerPage, query]);

  //   const numOfPages = Math.ceil(filteredProductCount / resultPerPage);
  //   const skip = resultPerPage * (curPage - 1);

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {/* {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? ( */}
        {error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Card>
            <Card.Header>
              <h2>Company Details</h2>
              {/* {staticContents &&
              staticContents[0]?.aboutUs?.length > 0 &&
              staticContents[0]?.contactUs?.length > 0 ? (
                "Company details"
              ) : (
                <Button
                  onClick={() => {
                    navigate(`/admin/companyDetails/add`);
                  }}
                  type="success"
                  className="btn btn-primary btn-block mt-1"
                >
                  Add Company Details
                </Button>
              )}
              <div className="search-box float-end">
                <InputGroup>
                  <Form.Control
                    aria-label="Search Input"
                    placeholder="Search"
                    type="search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQuery(searchInput);
                      setCurPage(1);
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div> */}
            </Card.Header>
            <Card.Body>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>About Us</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <CustomSkeleton resultPerPage={resultPerPage} column={9} />
                  ) : (
                    staticContents &&
                    staticContents?.map((content, i) => (
                      <tr key={content._id} className="odd">
                        <td className="text-center">{i + 1}</td>

                        <td>{content.aboutUs?.slice(0, 30)}...</td>
                        {/* <td>
                          <IoMdOpen
                            className="open-model"
                            onClick={() => showModelHandler(product.subProduct)}
                          />
                        </td> */}

                        <td>
                          {content?.contactUs?.phone?.map((phone) =>
                            phone ? (
                              <ul>
                                <li>{phone}</li>
                              </ul>
                            ) : (
                              <b>Phone not set</b>
                            )
                          )}
                        </td>
                        <td>
                          {content?.contactUs?.email?.map((email) =>
                            email ? (
                              <ul>
                                <li>{email}</li>
                              </ul>
                            ) : (
                              <b>Email not set</b>
                            )
                          )}
                        </td>
                        <td>
                          {content?.contactUs?.address?.map((address) =>
                            address ? (
                              <ul>
                                <li>{address?.slice(0, 30)}...</li>
                              </ul>
                            ) : (
                              <b>Address not set</b>
                            )
                          )}
                        </td>
                        {/* <td>
                          {product.sub_category ? (
                            product.sub_category.name
                          ) : (
                            <b>Sub category not set</b>
                          )}
                        </td> */}
                        <td>
                          <Button
                            onClick={() => {
                              // navigate(`/admin/view/company-info`);
                              navigate(
                                `/admin/view/companyDetails/${content?._id}`
                              );
                            }}
                            type="success"
                            className="btn btn-primary"
                          >
                            <FaEye />
                          </Button>
                          {/* <Button
                            onClick={() => {
                              deleteProduct(product._id);
                            }}
                            type="danger"
                            className="btn btn-danger ms-2"
                          >
                            <FaTrashAlt />
                          </Button> */}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              {/* <div className="float-start d-flex align-items-center mt-3">
                <p className="p-bold m-0 me-3">Row No.</p>
                <Form.Group controlId="resultPerPage">
                  <Form.Select
                    value={resultPerPage}
                    onChange={(e) => {
                      setResultPerPage(e.target.value);
                      setCurPage(1);
                    }}
                    aria-label="Default select example"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </Form.Select>
                </Form.Group>
              </div> */}
              {/* {resultPerPage < filteredProductCount && (
                <CustomPagination
                  pages={numOfPages}
                  pageHandler={curPageHandler}
                  curPage={curPage}
                />
              )} */}
            </Card.Footer>
          </Card>
        )}
        {/* {variant && modalShow ? (
          <QuantityArray
            show={modalShow}
            onHide={() => setModalShow(false)}
            arr={variant}
            column={{ "Quantity Type": "qname", Amount: "amount" }}
            title="Variant List"
          />
        ) : (
          <></>
        )} */}

        <ToastContainer />
      </Container>
    </motion.div>
  );
}
