import React, { useContext, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { addLatestNewsReducer as reducer } from "../../reducers/latestNews";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import axiosInstance from "../../utils/axiosUtil";
import { motion } from "framer-motion";

export default function AddLatestNews() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: "",
  });

  const [news, setNews] = useState("");

  const resetForm = () => {
    setNews("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch({ type: "FETCH_REQUEST" });
    try {
      const { data } = await axiosInstance.post(
        "/api/admin/add-latestNews",
        {
          news,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("product add data", data);

      if (data.latestNews) {
        toast.success("News added Succesfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        resetForm();

        setTimeout(() => {
          navigate("/admin/latestNews");
          dispatch({
            type: "FETCH_SUCCESS",
            payload: data,
          });
        }, 3000);
      } else {
        console.log(data);
        toast.error(data.error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(data.error.message),
        });
      }
    } catch (err) {
      dispatch({
        type: "FETCH_FAIL",
        payload: getError(err),
      });
      toast.error(getError(err), {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
        exit={{ x: "100%" }}
      >
        <Container fluid>
          <Row
            className="mt-2 mb-3"
            style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
          >
            <Col>
              <span style={{ fontSize: "xx-large" }}>Add latest news</span>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <Card.Header as={"h4"}>Add Customer review</Card.Header>
                <Form onSubmit={submitHandler}>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>News</Form.Label>
                      <Form.Control
                        as={"textarea"}
                        value={news}
                        onChange={(e) => setNews(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Button type="submit" disabled={loading ? true : false}>
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Card.Footer>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </motion.div>

      <ToastContainer />
    </>
  );
}
