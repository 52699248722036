export const getError = (error) => {
  if (error.errors && error.errors.length > 0) {
    const firstError = error.errors[0];
    return firstError.message;
  }
  
  return error.error && error.error.message
    ? error.error.message
    : 'An error occurred';
};
