import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../Store";
import { getError } from "../../utils/error";
import { editReducer as reducer } from "../../reducers/commonReducer";
import { uploadMultiImage } from "../../utils/uploadImage";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Modal,
  Form,
  Button,
  Container,
  ProgressBar,
  Row,
  Col,
} from "react-bootstrap";
import LoadingBox from "../layout/LoadingBox";
import axiosInstance from "../../utils/axiosUtil";

export default function EditStaticContent(props) {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;
  const { id } = useParams(); // product/:id

  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  const [aboutUs, setAboutUs] = useState("");
  const [contactUsPhone, setContactUsPhone] = useState([]);
  const [contactUsEmail, setContactUsEmail] = useState([]);
  const [contactUsAddr, setContactUsAddr] = useState([]);
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });

        const { data } = await axiosInstance.get(
          `/api/admin/get/static/${id}`,
          {
            headers: { Authorization: token },
          }
        );
        // console.log("edit product data", res, data);

        setAboutUs(data[0]?.aboutUs);
        setContactUsPhone(data[0]?.contactUs?.phone);
        setContactUsEmail(data[0]?.contactUs?.email);
        setContactUsAddr(data[0]?.contactUs?.address);
        // setPhone(data[0]?.contactUs?.phone);
        // setEmail(data[0]?.contactUs?.email);
        // setAddress(data[0]?.contactUs?.address);

        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
  }, [id, props.show]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!aboutUs) {
      toast.warning("All fields are required!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    if (!contactUsPhone || contactUsPhone?.length <= 0) {
      toast.warning("All fields are required!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    try {
      dispatch({ type: "UPDATE_REQUEST" });
      const { data } = await axiosInstance.put(
        `/api/admin/update/static/${id}`,
        {
          aboutUs,
          contactUsPhone,
          contactUsEmail,
          contactUsAddr,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("product update data", data);
      if (data) {
        toast.success("Details Updated Succesfully.  Redirecting...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setTimeout(() => {
          navigate("/admin/companyDetails");
          dispatch({ type: "UPDATE_SUCCESS" });
        }, 3000);
      } else {
        toast.error(data.error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (err) {
      dispatch({ type: "UPDATE_FAIL" });
      toast.error(getError(err), {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const contactUsHandler = () => {
    if (!phone || phone?.length < 10) {
      toast.warning("Phone is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!email) {
      toast.warning("Email is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!address) {
      toast.warning("Address is invalid!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    contactUsPhone?.push(phone);
    contactUsEmail?.push(email);
    contactUsAddr?.push(address);
    setPhone("");
    setEmail("");
    setAddress("");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitHandler}>
        <Modal.Body>
          <Container className="small-container">
            <Form.Group className="mb-3" controlId="aboutUs">
              <Form.Label>About Company</Form.Label>
              <Form.Control
                value={aboutUs}
                onChange={(e) => setAboutUs(e.target.value)}
                required
              />
            </Form.Group>

            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Button className="mt-4" onClick={contactUsHandler}>
                Add Contact Info
              </Button>
            </Row>

            <Row>
              {contactUsPhone && contactUsPhone.length > 0 && (
                <div className="table-responsive">
                  <table
                    id="example1"
                    className="table table-bordered table-striped col-6"
                  >
                    <thead>
                      <tr>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                        {/* <th>Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      //   key={contactUsPhone?.findIndex(
                      //     (q) => q.phone === phone
                      //   )}
                      >
                        <td>
                          {contactUsPhone?.map((phone, i) => (
                            <ul>
                              <li>{phone}</li>
                              <td>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = contactUsPhone.findIndex(
                                      (q) => q === phone
                                    );
                                    // console.log(
                                    //   contactUsAddr.findIndex((q) => q === addr)
                                    // );
                                    // console.log({ index });
                                    if (index > -1) {
                                      // only splice array when item is found

                                      setContactUsPhone([
                                        ...contactUsPhone?.slice(0, index),

                                        // part of the array after the given item
                                        ...contactUsPhone?.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  type="danger"
                                  className="btn btn-danger btn-block"
                                >
                                  Delete
                                </Button>
                              </td>
                            </ul>
                          ))}
                        </td>

                        <td>
                          {contactUsEmail.map((email, i) => (
                            <ul>
                              <li>{email}</li>
                              <td>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = contactUsEmail.findIndex(
                                      (q) => q === email
                                    );
                                    // console.log(
                                    //   contactUsAddr.findIndex((q) => q === email)
                                    // );
                                    // console.log({ index });
                                    if (index > -1) {
                                      // only splice array when item is found

                                      setContactUsEmail([
                                        ...contactUsEmail?.slice(0, index),

                                        // part of the array after the given item
                                        ...contactUsEmail?.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  type="danger"
                                  className="btn btn-danger btn-block"
                                >
                                  Delete
                                </Button>
                              </td>
                            </ul>
                          ))}
                        </td>

                        <td>
                          {contactUsAddr.map((addr, i) => (
                            <ul>
                              <li>{addr}</li>
                              <td>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = contactUsAddr.findIndex(
                                      (q) => q === addr
                                    );
                                    console.log(
                                      contactUsAddr.findIndex((q) => q === addr)
                                    );
                                    console.log({ index });
                                    if (index > -1) {
                                      // only splice array when item is found

                                      setContactUsAddr([
                                        ...contactUsAddr?.slice(0, index),

                                        // part of the array after the given item
                                        ...contactUsAddr?.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  type="danger"
                                  className="btn btn-danger btn-block"
                                >
                                  Delete
                                </Button>
                              </td>
                            </ul>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Row>

            <ToastContainer />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={loadingUpdate ? true : false}
          >
            Submit
          </Button>
          {loadingUpdate && <LoadingBox></LoadingBox>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
